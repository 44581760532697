// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-preview-js": () => import("/app/src/templates/preview.js" /* webpackChunkName: "component---src-templates-preview-js" */),
  "component---src-templates-404-js": () => import("/app/src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-post-js": () => import("/app/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-page-js": () => import("/app/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/app/.cache/data.json")

