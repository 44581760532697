/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/layout/normalize.css"
import "./src/layout/global.css"

const getTargetOffset = (hash) => {
  const id = window.decodeURI(hash.replace(`#`, ``))
  if (id !== ``) {
    const element = document.getElementById(id)
    if (element) return element.getBoundingClientRect().top + window.scrollY
  }
  return null
}

export const onInitialClientRender = () => {
  // force route update when initially open a page if a hash is in the url to make it scroll to the right position
  if (window.location.hash) window.location.href = window.location.hash
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    const offset = getTargetOffset(location.hash)
    if (offset === null) {
      window.location.href = window.location.href.split(`#`)[0]
      return false
    } else return [0, offset]
  }
  return [0, 0]
}

export const onRouteUpdate = ({ location }) => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(location.href)
  window.__previousPath = window.locations[window.locations.length - 1]
}
